:root{
  --form-width:400px;
}
.form-container {
    width: var(--form-width) !important;
    background-color: #fff;
    padding: 32px 24px;
    font-size: 14px;
    font-family: inherit;
    color: #212121;
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
  }
  
  .form-container button:active {
    scale: 0.95;
  }
  
  .form-container .logo-container {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
  }
  
  .form-container .form {
    display: flex;
    flex-direction: column;
  }
  
  .form-container .form-group {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-bottom: 10px;
  }
  
  .form-container .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-container .form-group input {
    width: 100%;
    padding: 12px 16px;
    border-radius: 6px;
    font-family: inherit;
    border: 1px solid #ccc;
    box-sizing: border-box;
  }
  
  .form-container .form-group input::placeholder {
    opacity: 0.5;
  }
  
  .form-container .form-group input:focus {
    outline: none;
    border-color: #1778f2;
  }
  
  .form-container .form-submit-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: inherit;
    color: #fff;
    background-color: #212121;
    border: none;
    width: 100%;
    padding: 12px 16px;
    font-size: inherit;
    gap: 8px;
    margin: 12px 0;
    cursor: pointer;
    border-radius: 6px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
  }
  
  .form-container .form-submit-btn:hover {
    background-color: #313131;
  }
  .monaco-editor {
    padding-top: 15px !important;
  }
  
  