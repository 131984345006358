.header-button-modal.modal.show > div.modal-dialog > div.modal-content{
    min-width: 40vw;
    max-width: 40vw;
}
.header-button-modal.modal.show > div.modal-dialog {
    justify-content: center;
}
.launchpad-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
    padding: 10px;
    border-bottom: 1px solid #dee2e6;
  }
  
  .launchpad-item:hover {
    background-color: #f5f5f5;
  }
  
  .edit-icon {
    visibility: hidden;
  }
  
  .launchpad-item:hover .edit-icon {
    visibility: visible;
  }
  
  .hidden-action {
    opacity: 0.5; 
    filter: blur(2px); 
    border: 2px dashed #ccc; 
    border-style: dashed; 
  }
  
  