.widget-header-main {
  border-left: 1px solid #f8f9fc;
  height: 72px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 800;
  border-bottom: 1px solid #f3f5f9;
  background-color: #fff;
  display: flex;
  align-items: center;
  transition: all 0.25s;
  box-shadow: 0 1px 1px rgba(33, 40, 48, 0.01), 0 4px 4px rgba(33, 40, 48, 0.01),
    0 16px 16px rgba(33, 40, 48, 0.01);
}
.widget-sidebar {
  background-color: #202020;
  width: 365px;
  opacity: 1;
  visibility: visible;
  left: 0;
  height: 100vh;
  position: fixed;
  top: 72px;
  z-index: 1000;
  transition: all 0.25s;
}
.widget-setting-sidebar {
  background-color: #202020;
  width: 80px;
  opacity: 1;
  visibility: visible;
  left: 0;
  height: 100vh;
  position: fixed;
  top: 72px;
  z-index: 1000;
  transition: all 0.25s;
}
.widget-setting-content {
  margin-top: 72px;
  margin-left: 80px;
  width: calc(100% - 380px) !important;
  min-height: calc(100vh - 72px) !important;
}
.widget-content {
  margin-top: 72px;
  margin-left: 365px;
  width: calc(100% - 365px) !important;
  min-height: calc(100vh - 72px) !important;
}
.widget-card-layer {
  position: relative;
  cursor: pointer;
  width: 100%;
  border-radius: 4px;
  min-height: 203px;
}
.widget-card {
  position: relative;
  padding: 16px;
  height: 160px;
  border-radius: 4px;
  background: #343434;
  display: flex;
  justify-content: center;
  align-items: center;
}
.widget-card.active {
  outline: 2px #1494ff solid;
}
.widget-card.active::before {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 16px;
  height: 16px;
  border-radius: 10px;
  background-color: #1494ff;
  content: "\2713"; /* HTML entity for a checkmark */
  color: #fff; /* Set the color of the icon */
  font-size: 12px; /* Adjust the font size as needed */
  text-align: center;
  line-height: 16px;
}
.settings-sidebar {
  height: 100vh;
  width: 320px;
  position: fixed;
  top: 72px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  right: 0;
  background-color: #f8f9fa;
  border-left: 1px solid #f3f5f9;
  padding: 10px 0px;
  display: block;
}
